@import "./variables.scss";
@import "./mixin.scss";

#resume {
  transition: all 0.25s ease-in-out;

  .resume {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 8rem;

    .h1-leave {
      opacity: 0.00;
      transition: opacity 300ms ease-in;
      //display: none;
    }

    h1 {
      text-align: center;
      color: $main-text-color;
      font-size: 2rem;
      transition: 1s;
    }


    .resume-item {
      border: 1px solid;
      border-radius: 10px;
      margin: 5px 0 13px 0;
      background: #1f172c;
      box-shadow: 0px 0px 24px -19px #fff;


      h5 {
        color: $main-text-color;
      }

      p {
        color: $main-text-color;
      }
    }

  }
}