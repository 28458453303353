@import "./variables.scss";
@import "./mixin.scss";

#social {
  position: fixed;
  right: 0;
  left: 0;

  transition: 1s;
  text-align: center;
  background-color: #bf001170;
  bottom: 0;

  @include media-screen($md) {
    bottom: 1rem;
    background-color: unset;
  }


  a {
    padding: .5rem;
      &:hover {
        //box-shadow: 1px 1px 1px #fff;
        //transform: scale(5);
      }
  }
}