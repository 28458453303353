@import "./variables.scss";

#contact {
  transition: all 0.25s ease-in-out;

  .contact-box {
    height: 100vh;
    display: flex;

    .h1-leave {
      opacity: 0.00;
      transition: opacity 300ms ease-in;
      //display: none;
    }

    h1 {
      text-align: center;
      color: $main-text-color;
      font-size: 2rem;
      transition: 1s;
    }

    p {
      color: $main-text-color;
    }

  }
}