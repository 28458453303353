@import "./variables.scss";

#menu {
  width: 180px;
  height: 100%;
  position: fixed;
  background-color: #a92d38;
  z-index: 9;
  transition: all 0.25s ease-in-out;
  left: -200px;
  top: 0;

  ul {
    list-style: none;
    text-align: center;
    padding: 0;
    margin-top: 50px;

    li {
      margin-top: 1rem;

      a {
        color: $main-text-color;
        font-size: 1.3rem;
        &.active{
          color: #000;
          font-weight: 900;
        }

        &:hover {
          text-decoration: none;
          color: #89a0a9;
        }
      }
    }
  }
}

.active-menu {
  left: 0 !important;

  .close {
    padding: 6px;
    cursor: pointer;
  }
}
