@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Source+Code+Pro:wght@200&display=swap);
#about .about-sec{height:100vh}#about h1{text-align:center;color:#d6d6cf;font-size:2rem}#about p{color:#d6d6cf}

#profile{transition:all 0.25s ease-in-out;padding-bottom:12rem}@media (min-width: 768px){#profile{padding-bottom:0}}#profile .profile-avatar{height:100vh}#profile h1{text-align:center;color:#d6d6cf;font-size:2rem}#profile p{color:#d6d6cf}#profile .avatar{max-width:200px;-webkit-filter:drop-shadow(-4px 2px 8px black);filter:drop-shadow(-4px 2px 8px black)}

#contact{transition:all 0.25s ease-in-out}#contact .contact-box{height:100vh;display:flex}#contact .contact-box .h1-leave{opacity:0.00;transition:opacity 300ms ease-in}#contact .contact-box h1{text-align:center;color:#d6d6cf;font-size:2rem;transition:1s}#contact .contact-box p{color:#d6d6cf}

#skill{transition:all 0.25s ease-in-out}#skill .skills{display:flex;margin:2rem 0 8rem 0}@media (min-width: 768px){#skill .skills{height:100vh;margin:unset}}#skill .skills h1{text-align:center;color:#d6d6cf;font-size:2rem}#skill .skills p{color:#d6d6cf}#skill .skills .avatar{max-width:200px}#skill .skills .skill-box{border:1px solid;border-radius:9px;padding-top:23px;box-shadow:1px 1px 45px -37px #fff;background:#1f172c}#skill .skills .skill-box .skill-item p{color:#d6d6cf}

#resume{transition:all 0.25s ease-in-out}#resume .resume{display:flex;margin-top:2rem;margin-bottom:8rem}#resume .resume .h1-leave{opacity:0.00;transition:opacity 300ms ease-in}#resume .resume h1{text-align:center;color:#d6d6cf;font-size:2rem;transition:1s}#resume .resume .resume-item{border:1px solid;border-radius:10px;margin:5px 0 13px 0;background:#1f172c;box-shadow:0px 0px 24px -19px #fff}#resume .resume .resume-item h5{color:#d6d6cf}#resume .resume .resume-item p{color:#d6d6cf}

#menu{width:180px;height:100%;position:fixed;background-color:#a92d38;z-index:9;transition:all 0.25s ease-in-out;left:-200px;top:0}#menu ul{list-style:none;text-align:center;padding:0;margin-top:50px}#menu ul li{margin-top:1rem}#menu ul li a{color:#d6d6cf;font-size:1.3rem}#menu ul li a.active{color:#000;font-weight:900}#menu ul li a:hover{text-decoration:none;color:#89a0a9}.active-menu{left:0 !important}.active-menu .close{padding:6px;cursor:pointer}

#social{position:fixed;right:0;left:0;transition:1s;text-align:center;background-color:#bf001170;bottom:0}@media (min-width: 768px){#social{bottom:1rem;background-color:unset}}#social a{padding:.5rem}

body{background:linear-gradient(90deg, #0a0118 0%, rgba(10,1,24,0.896796) 46%, rgba(10,1,24,0.804359) 100%);font-family:'Source Code Pro', monospace;font-size:16px}body .menu-btn{left:20px;top:10px;color:#fff;font-size:1.5rem;position:fixed;cursor:pointer;z-index:9}body .menu-btn:before{content:'';width:0;height:30px;left:-3px;top:5px;position:absolute;border:1px solid #fff;border-radius:50%;overflow:hidden;transition:all 0.25s ease-in-out}body .menu-btn:hover:before{width:30px}

