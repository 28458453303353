@import "./variables.scss";
@import "./mixin.scss";

#profile{
  transition: all 0.25s ease-in-out;
  padding-bottom: 12rem;
  @include media-screen($md) {
    padding-bottom: 0;
  }

  .profile-avatar{
    height: 100vh;
  }

  h1{
    text-align: center;
    color: $main-text-color;
    font-size: 2rem;
  }

  p{
    color: $main-text-color;
  }

  .avatar{
    max-width: 200px;
    filter: drop-shadow(-4px 2px 8px black);
  }
}