@import "./variables.scss";

#about {
  .about-sec {
    height: 100vh;
  }

  h1 {
    text-align: center;
    color: $main-text-color;
    font-size: 2rem;
  }

  p {
    color: $main-text-color;
  }
}