@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Source+Code+Pro:wght@200&display=swap');



body {
  //background-color: #0a0118;
  background: linear-gradient(90deg, rgba(10,1,24,1) 0%, rgba(10,1,24,0.896796218487395) 46%, rgba(10,1,24,0.804359243697479) 100%);
  //font-family: 'Merriweather', serif;
  font-family: 'Source Code Pro', monospace;
  font-size: 16px;

  .menu-btn {
    left: 20px;
    top: 10px;
    color: #fff;
    font-size: 1.5rem;
    position: fixed;
    cursor: pointer;
    z-index: 9;

    &:before {
      content: '';
      width: 0;
      height: 30px;
      left: -3px;
      top: 5px;
      position: absolute;
      border: 1px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all 0.25s ease-in-out;

    }

    &:hover {
      &:before {
        width: 30px;
      }
    }

  }
}
