@import "./variables.scss";
@import "./mixin.scss";

#skill {
  transition: all 0.25s ease-in-out;

  .skills {
    //height: 100vh;
    display: flex;
    margin: 2rem 0 8rem 0;
    @include media-screen($md) {
      height: 100vh;
      margin: unset;
    }

    h1 {
      text-align: center;
      color: $main-text-color;
      font-size: 2rem;
    }

    p {
      color: $main-text-color;
    }

    .avatar {
      max-width: 200px;
    }

    .skill-box {
      border: 1px solid;
      border-radius: 9px;
      padding-top: 23px;
      //box-shadow: 1px 1px 45px -33px #fff;
      box-shadow: 1px 1px 45px -37px #fff;
      background: #1f172c;

      .skill-item {
        p {
          color: $main-text-color;

        }
      }
    }
  }

}